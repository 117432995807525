import React from 'react'
import styles from './style.module.sass'

export default ({ children }) => {
  return (
    <div className={styles.navigationWrap}>
      <div className={styles.wrap}>{children}</div>
    </div>
  )
}
