import React from 'react'
import cx from 'classnames'
import { prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ material }) => {
  return (
    <>
      <div className={styles.material}>
        <div className={styles.anchor} id={titleToId(material.titleSection)} data-js="scroll-section" />
        <h2 className={styles.title}>{material.titleSection}</h2>
      </div>

      <div className={styles.qadam}>
        <div className={styles.anchor} id={titleToId(material.title)} data-js="scroll-section" />
        <h2 className={styles.title}>{material.title}</h2>
        <div className={styles.content}>
          {material.Content &&
            material.Content.map(block => (
              <div className={styles.paragraph}>
                {block.body &&
                  block.body.map(item => (
                    <div className={styles.item}>
                      {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                      {item._type === 'divider' && <div className={styles.divider} />}

                      {item._type === 'table2Columns' && (
                        <div className={styles.table2}>
                          <div className={styles.table}>
                            <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: item.title1 }} />
                            <ul className={styles.tableList}>
                              {item.items1 &&
                                item.items1.map((item, index) => (
                                  <li key={index} className={styles.tableItem}>
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div className={styles.table}>
                            <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: item.title2 }} />
                            <ul className={styles.tableList}>
                              {item.items2 &&
                                item.items2.map((item, index) => (
                                  <li key={index} className={styles.tableItem}>
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
