import React, { useEffect } from 'react'
import cx from 'classnames'
import styles from './style.module.sass'

const LinkHTML = ({ item, index, sub = false, sub2 = false }) => {
  return (
    <li
      className={cx(styles.item, { [styles.itemSub]: sub, [styles.itemSub2]: sub2 })}
      key={index}
      data-js-id={item.id}
    >
      <a className={styles.link} href={item.link}>
        {item.name}
      </a>
    </li>
  )
}

export default ({ links, inside = false, bold = false }) => {
  const scrollHandler = () => {
    if (!window) return false

    const sections = document.querySelectorAll("[data-js='scroll-section']")
    const navLi = document.querySelectorAll(`.${styles.item}`)

    let current = ''

    sections.forEach(section => {
      const sectionTop = section.getBoundingClientRect().top
      if (sectionTop <= 1) {
        current = section.getAttribute('id')
      }
    })

    if (current === '') {
      navLi[0].classList.add(styles.itemActive)
    } else {
      navLi.forEach(li => {
        li.classList.remove(styles.itemActive)
        if (li.getAttribute('data-js-id') === current) {
          li.classList.add(styles.itemActive)
        }
      })
    }
  }

  useEffect(() => {
    if (inside) {
      return
    }

    scrollHandler()

    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [inside])

  return (
    <div
      className={cx(styles.navigation, {
        [styles.navigationInside]: inside,
        [styles.navigationBold]: bold,
      })}
    >
      <ul className={styles.list}>
        {links.map((item, index) => (
          <>
            <LinkHTML item={item} index={index} />
            {!!item.children.length &&
              item.children.map((item2, index2) => (
                <>
                  <LinkHTML item={item2} index={index2} sub={true} />
                  {!!item2.children.length &&
                    item2.children.map((item3, index3) => (
                      <LinkHTML item={item3} index={index3} sub={true} sub2={true} />
                    ))}
                </>
              ))}
          </>
        ))}
      </ul>
    </div>
  )
}
