import React from 'react'
import Layout from '../../utils/layout'
import SEO from '../../utils/seo'
import Header from '../../components/WhitePaper/Testosterone/Header'
import Navigation from '../../components/WhitePaper/Nav/Navigation'
import NavigationWrap from '../../components/WhitePaper/Nav/NavigationWrap'
import NavigationContent from '../../components/WhitePaper/Nav/NavigationContent'
import Info from '../../components/WhitePaper/Testosterone/Info'
import Abstract from '../../components/WhitePaper/Testosterone/Abstract'
import Declining from '../../components/WhitePaper/Testosterone/Declining'
import Options from '../../components/WhitePaper/Testosterone/Options'
import Study from '../../components/WhitePaper/Testosterone/Study'
import Data from '../../components/WhitePaper/Testosterone/Data'
import Analysis from '../../components/WhitePaper/Testosterone/Analysis'
import Biomarker from '../../components/WhitePaper/Testosterone/Biomarker'
import Symptom from '../../components/WhitePaper/Testosterone/Symptom'
import Findings from '../../components/WhitePaper/Testosterone/Findings'
import Limitations from '../../components/WhitePaper/Testosterone/Limitations'
import References from '../../components/WhitePaper/Testosterone/References'
import Appendix from '../../components/WhitePaper/Testosterone/Appendix'
import Material from '../../components/WhitePaper/Testosterone/Material'
import { titleToId, buildLink } from '../../utils/format'

export default ({ pageContext: { data } }) => {
  const links = [
    buildLink(data.abstract.title),
    buildLink(data.declining.titleSection, [data.declining.title, data.options.title]),
    buildLink(data.study.titleSection, [
      data.study.title,
      buildLink(data.data.title, [...data.data.Content.map(i => i.title)]),
      data.analysis.title,
    ]),
    buildLink(data.biomarker.titleSection, [data.biomarker.title, data.symptom.title]),
    buildLink(data.findings.titleSection, [data.findings.title, data.limitations.title]),
    buildLink(data.references.title),
    buildLink(data.appendix.title),
    buildLink(data.material.titleSection),
  ]

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Header links={links} header={data.header} />
      <NavigationWrap>
        <Navigation links={links} />
        <NavigationContent>
          <Info info={data.info} />
          <Abstract abstract={data.abstract} />
          <Declining declining={data.declining} />
          <Options options={data.options} />
          <Study study={data.study} />
          <Data data={data.data} />
          <Analysis analysis={data.analysis} />
          <Biomarker biomarker={data.biomarker} />
          <Symptom symptom={data.symptom} />
          <Findings findings={data.findings} />
          <Limitations limitations={data.limitations} />
          <References references={data.references} />
          <Appendix appendix={data.appendix} />
          <Material material={data.material} />
        </NavigationContent>
      </NavigationWrap>
    </Layout>
  )
}
