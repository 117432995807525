import React from 'react'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ declining }) => {
  return (
    <>
      <div className={styles.background}>
        <div className={styles.anchor} id={titleToId(declining.titleSection)} data-js="scroll-section" />
        <h2 className={styles.title}>{declining.titleSection}</h2>
      </div>

      <div className={styles.declining}>
        <div className={styles.anchor} id={titleToId(declining.title)} data-js="scroll-section" />
        <h2 className={styles.title}>{declining.title}</h2>
        <div className={styles.content}>
          {declining.content &&
            declining.content.map(item => (
              <div className={styles.item}>
                {item._type === 'paragraph' && (
                  <div className={styles.paragraph}>
                    <div className={styles.left}>
                      <p className={styles.paragraphTitle}>{prepareParagraph(item.title)}</p>
                      <p className={styles.paragraphText}>{prepareParagraph(item.text)}</p>
                    </div>
                    <div className={styles.right}>
                      <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                    </div>
                  </div>
                )}

                {item._type === 'warning' && <p className={styles.warning}>{prepareParagraph(item.warning)}</p>}

                {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
