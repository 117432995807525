import React from 'react'
import { prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ analysis }) => {
  return (
    <div className={styles.analysis}>
      <div className={styles.anchor} id={titleToId(analysis.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{analysis.title}</h2>

      <div className={styles.content}>
        {analysis.Content &&
          analysis.Content.map(block => (
            <div className={styles.paragraph}>
              {block.body &&
                block.body.map(item => (
                  <div className={styles.item}>
                    {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}
