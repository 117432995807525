import React from 'react'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ biomarker }) => {
  return (
    <>
      <div className={styles.results}>
        <div className={styles.anchor} id={titleToId(biomarker.titleSection)} data-js="scroll-section" />
        <h2 className={styles.title}>{biomarker.titleSection}</h2>
      </div>

      <div className={styles.biomarker}>
        <div className={styles.anchor} id={titleToId(biomarker.title)} data-js="scroll-section" />
        <h2 className={styles.title}>{biomarker.title}</h2>

        <div className={styles.content}>
          {biomarker.Content &&
            biomarker.Content.map(block => (
              <div className={styles.paragraph}>
                <p className={styles.headline}>{prepareParagraph(block.title)}</p>

                {block.body &&
                  block.body.map(item => (
                    <div className={styles.item}>
                      {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                      {item._type === 'list' && (
                        <div className={styles.list}>
                          <ul className={styles.listItems}>
                            {item.items &&
                              item.items.map((item, index) => (
                                <li key={index} className={styles.listItem}>
                                  <p className={styles.listResult}>{item.result}</p>
                                  <img
                                    src={sanityImageUrl(item.icon)}
                                    className={styles.listImage}
                                    alt={item.icon?.caption}
                                  />
                                  <p className={styles.listTitle}>{item.title}</p>
                                  <p className={styles.listText}>{item.text}</p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}

                      {item._type === 'imageFull' && (
                        <div className={styles.image}>
                          <p className={styles.caption}>{prepareParagraph(item.title)}</p>
                          <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                          {item.disclaimer && <p className={styles.disclaimer}>{prepareParagraph(item.disclaimer)}</p>}
                        </div>
                      )}

                      {item._type === 'divider' && <div className={styles.divider} />}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
