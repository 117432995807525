import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ abstract }) => {
  return (
    <div className={styles.abstract}>
      <div className={styles.anchor} id={titleToId(abstract.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{abstract.title}</h2>

      <div className={styles.list}>
        <ul className={styles.listItems}>
          {abstract.shortBenefits &&
            abstract.shortBenefits.map((item, index) => (
              <li className={styles.listItem} key={item._key}>
                <img src={sanityImageUrl(item.icon)} className={styles.listImage} alt={item.icon?.caption} />
                <p className={styles.listText}>{item.title}</p>
              </li>
            ))}
        </ul>
      </div>

      <div className={styles.content}>
        <div className={styles.text}>
          {abstract.longBenefits &&
            abstract.longBenefits.map((item, index) => (
              <div className={styles.textItem} key={item._key}>
                <p className={styles.textTitle}>{item.title}</p>
                <p className={styles.textInfo}>{prepareParagraph(item.text)}</p>
              </div>
            ))}
        </div>
        <div className={styles.image}>
          <img src={sanityImageUrl(abstract.longBenefitsImage)} alt={abstract.longBenefitsImage?.caption} />
        </div>
      </div>
    </div>
  )
}
