import React from 'react'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ symptom }) => {
  return (
    <div className={styles.symptom}>
      <div className={styles.anchor} id={titleToId(symptom.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{symptom.title}</h2>

      <div className={styles.content}>
        {symptom.Content &&
          symptom.Content.map(block => (
            <div className={styles.paragraph}>
              <p className={styles.headline}>{prepareParagraph(block.title)}</p>

              {block.body &&
                block.body.map(item => (
                  <div className={styles.item}>
                    {item._type === 'info' && (
                      <div className={styles.info}>
                        <p className={styles.text}>{prepareParagraph(item.info)}</p>
                        <div className={styles.image}>
                          <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                        </div>
                      </div>
                    )}

                    {item._type === 'list' && (
                      <div className={styles.list}>
                        <ul className={styles.listItems}>
                          {item.items &&
                            item.items.map((item, index) => (
                              <li key={index} className={styles.listItem}>
                                <p className={styles.listResult}>{item.result}</p>
                                <img
                                  src={sanityImageUrl(item.icon)}
                                  className={styles.listImage}
                                  alt={item.icon?.caption}
                                />
                                <p className={styles.listTitle}>{item.title}</p>
                                <p className={styles.listText}>{item.text}</p>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}

                    {item._type === 'imageFull' && (
                      <div className={styles.image}>
                        <p className={styles.caption}>{prepareParagraph(item.title)}</p>
                        <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                        <p className={styles.disclaimer}>{prepareParagraph(item.disclaimer)}</p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}
